/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  console.log('ReactDOM.render has executed');

  var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq ||
    {widgetcode:"<widgetcode>", values:{},ready:function(){}};
  var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;
  s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);

  // window.$zoho = window.$zoho || {};
  // window.$zoho.salesiq = window.$zoho.salesiq || {
  //   widgetcode:
  //     '145ae22cee43b148eead80d1f2bc083637b77e0652ffb9a55235c217458f9ac70b5e354ee404cab7a41e04eb58edaf7d',
  //   values: {},
  //   ready: function() {},
  // };
  // let d = document;
  // let s = d.createElement('script');
  // s.type = 'text/javascript';
  // s.id = 'zsiqscript';
  // s.defer = true;
  // s.src = 'https://salesiq.zoho.eu/widget';
  // let t = d.getElementsByTagName('script')[0];
  // t.parentNode.insertBefore(s, t);
  // d.write("<div id='zsiqwidget'></div>");
};
