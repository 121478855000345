module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W4C5ZG4","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 340px)","sm":"(max-width: 600px)","md":"(max-width: 960px)","lg":"(max-width: 1280px)","xl":"(max-width: 1920px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141826317-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"breakpoints":{"keys":["xxs","xs","sm","md","lg","xl"],"values":{"xxs":0,"xs":340,"sm":600,"md":960,"lg":1280,"xl":1920}},"palette":{"primary":{"main":"#592ea0"},"secondary":{"main":"#db2156"},"background":{"default":"#FFF"}},"typography":{"useNextVariants":true,"fontSize":16,"fontFamily":"\"Nunito\", \"Roboto\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","h1":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontSize":54,"fontWeight":"bold"},"h2":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontSize":48,"fontWeight":"bold"},"h3":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontWeight":"bold"},"h4":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontWeight":"bold"},"h5":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontSize":36,"fontWeight":"bold"},"h6":{"fontFamily":"\"Montserrat\", Arial, \"Helvetica Neue\", Helvetica, sans-serif","fontSize":30,"fontWeight":"bold"},"button":{"fontWeight":700},"subtitle2":{"fontSize":20,"fontWeight":"bold"},"body1":{"fontSize":24},"body2":{"fontSize":18},"caption":{"fontSize":14}},"shape":{"borderRadius":20},"overrides":{"MuiCssBaseline":{"@global":{"@font-face":{"fontDisplay":"swap"}}},"MuiButton":{"root":{"border-radius":"3000px","text-transform":"initial","box-shadow":"none"},"contained":{"box-shadow":"none"}}}}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Q°emotion - Emotional analysis thanks to AI","short_name":"Q°emotion","start_url":"/en","background_color":"#592ea0","theme_color":"#592ea0","display":"minimal-ui","icon":"src/images/qemotion-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4ea2ce1315965d2a58f16980c8d3f1b5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
